@import 'responsive';
@import 'normalise';

html, body {
    padding: 0;
    margin: 0;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.3em;
    color: #666;

    @include respond-to(small) {
        font-size: 1.3em;
    }
}

h1, h2 {
    font-family: Dancing Script, cursive;
    color: green;
    border-bottom: dotted 2px silver;
    line-height: 3rem;
}

nav {
    display: flex;
    justify-content: space-between;
    margin: 2em auto;

    @include respond-to(medium) {
        width: 900px;
    }

    @include respond-to-under(small) {
        flex-direction: column;
        margin: 1em;
    }

    a {
        text-decoration: none;

        &:link, &:active, &:visited {
            color: #666;
        }
        
        &:hover {
            text-decoration: underline;
            color: #E3456E;
        }
    }
}

section {
    .container {
        @include respond-to(medium) {
            width: 900px;
        }

        margin: 0 auto;
        padding: 0 1rem 3rem 1rem;
    }

    &.splash .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // min-height: 100vh;
        background: url(../images/studio-1.png) bottom center;
        background-size: cover;
        // background-attachment: fixed;
        width: 100vw;
        padding: 0;
        
        .badge {
            height: 90%;
            min-height: 300px;
            max-height: 500px;
            margin: 1em;
        }
    }

    &.gallery {
        width: 100%;
    }
}

img.contextual {
    width: 100%;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, .5);

    @include respond-to(small) {
        width: 30%;
        float: right;
        margin: 0 0 1rem 1rem;
    }
}

.photo-album {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;

        img {
            width: 250px;
        }
    }
}
