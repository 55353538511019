$breakpoints: (
  'small'  : ( min-width:  767px ),
  'medium' : ( min-width:  992px ),
  'large'  : ( min-width: 1200px )
);

$breakpointsUnder: (
  'small'  : ( max-width:  766px ),
  'medium' : ( max-width:  991px ),
  'large'  : ( max-width: 1199px )
);

@mixin respond-to($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin respond-to-under($name) {
  // If the key exists in the map
  @if map-has-key($breakpointsUnder, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpointsUnder, $name))} {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpointsUnder` map.";
  }
}
